@import 'src/styles/variables';

.item {
  padding: 20px;
  background: $white;
  height: 140px;
  // margin: 15px 5px;
  box-shadow: $basic-shadow;
  border-radius: 3px;
  position: relative;
  text-decoration: none;
  display: flex;
  width: 240px;
  flex-direction: column;
  transition: transform 0.3s $cubic;

  &:before {
    content: '';
    position: absolute;
    z-index: $z-behind;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0 5px 1px rgba(4, 13, 71, 0.2);
    mix-blend-mode: multiply;
    opacity: 0;
    top: 0;
    transition: opacity 0.6s $cubic;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    transform: translateY(-5px);
  }
}

$arrow-width: 80px;

.container {
  overflow: hidden;
  margin: auto;
  outline: none;

  // Add 40px to cover the arrows
  max-width: 1200px + 80px;

  @include for-size(desktop-down, 40) {
    max-width: 900px + 50px;
  }

  @include for-size(tablet-landscape-down, 40) {
    max-width: 600px + 45px;
  }

  @include for-size(tablet-portrait-down, 40) {
    max-width: 300px + 40px;
  }

  :global {
    .slider-list {
      height: 216px !important;
    }
  }

  // :global {

  //   .slider-list {
  //     background: green;
  //   }

  //   .slider-slide:not(.slide-visible) {
  //     opacity: .5;
  //   }
  // }
}

.header {
  color: $dark-blue;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}

.link {
  text-decoration: none;
  flex: 0 0 234px;
  margin: 10px 10px;
}

.company {
  display: flex;
  margin-top: auto;
  align-items: center;
}

.image {
  display: inline-block !important;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  margin-right: 10px;
}

.companyName {
  margin: 0px;
  display: inline-block;
  color: #4a474a;
  font-size: 15px;
  font-weight: 500;
}

.arrowPrev {
  &.dark {
    filter: none;

    &:hover {
      filter: none;
    }

    path {
      fill: $dark-blue;
    }
  }

  cursor: pointer;
  padding: 5px;
  margin-left: -10px;
  border-radius: 50%;
  transform: rotate(180deg);
  width: 30px;
  height: 30px;
  // filter: drop-shadow($arrow-shadow) drop-shadow($arrow-shadow2);

  // &:hover {
  //   filter: drop-shadow($arrow-shadow-hover);
  // }
}

.arrowNext {
  &.dark {
    filter: none;

    &:hover {
      filter: none;
    }

    path {
      fill: $dark-blue;
    }
  }

  cursor: pointer;
  padding: 5px;
  margin-right: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  // filter: drop-shadow($arrow-shadow) drop-shadow($arrow-shadow2);

  // &:hover {
  //   filter: drop-shadow($arrow-shadow-hover);
  // }
}
